import "./Indicador.scss"
import { Col } from "react-bootstrap"

const Indicador = ({ image, number, text }) => {
    return (
        <Col md={3} className="indicador">
            <div className="img-container">
                <img src={ image } alt={ text } />
            </div>
            <strong>{ number }</strong>
            <p>{ text }</p>
        </Col>
    )
}

export default Indicador