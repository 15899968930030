import { Col, Container, Row } from "react-bootstrap"
import ImageGallery from "react-image-gallery"



const Galeria = () => {    
    var images = [
        {
          original: 'galeria/g3.jpg',
          thumbnail: 'galeria/g3.jpg',
        },
        {
            original: 'galeria/g4.jpg',
            thumbnail: 'galeria/g4.jpg',
        },
        {
            original: 'galeria/g5.jpg',
            thumbnail: 'galeria/g5.jpg',
        },
        {
            original: 'galeria/g6.jpg',
            thumbnail: 'galeria/g6.jpg',
        },
        {
            original: 'galeria/g7.jpg',
            thumbnail: 'galeria/g7.jpg',
        },
        {
            original: 'galeria/g8.jpg',
            thumbnail: 'galeria/g8.jpg',
        },
        {
            original: 'galeria/g9.jpg',
            thumbnail: 'galeria/g9.jpg',
        },
        {
            original: 'galeria/g10.jpg',
            thumbnail: 'galeria/g10.jpg',
        },
        {
            original: 'galeria/g11.jpg',
            thumbnail: 'galeria/g11.jpg',
        },
        {
            original: 'galeria/g12.jpg',
            thumbnail: 'galeria/g12.jpg',
        },
        {
            original: 'galeria/g13.jpg',
            thumbnail: 'galeria/g13.jpg',
        },
        {
            original: 'galeria/g14.jpg',
            thumbnail: 'galeria/g14.jpg',
        },
        {
            original: 'galeria/g15.jpg',
            thumbnail: 'galeria/g15.jpg',
        },
        {
            original: 'galeria/g16.jpg',
            thumbnail: 'galeria/g16.jpg',
        },
        {
            original: 'galeria/g17.jpg',
            thumbnail: 'galeria/g17.jpg',
        },
        {
            original: 'galeria/g18.jpg',
            thumbnail: 'galeria/g18.jpg',
        },
        {
            original: 'galeria/g19.jpg',
            thumbnail: 'galeria/g19.jpg',
        },
    ]

    return (
        <Container className="mt-5 mb-3">
            <Row>
                <Col md={12}>
                    <h4 style={{ textAlign:"center" }} className="mb-5">Galería de Fotos</h4>
                    <ImageGallery items={images} />
                </Col>
            </Row>
        </Container>
        
    )
}

export default Galeria