import "./Title.scss"
import logo_iica from "../static/logos/logo_iica.svg"
import logo_ue from "../static/logos/logo-ue.png"
import { Col, Row } from "react-bootstrap"

const Title = () => {
    return (
        <div className="container-fluid title">
            <Row>
                <Col md={{ span:5, offset:1 }} sm={6}>
                    <div className="logo">
                        <img className="logoue" src={ logo_ue } alt="Logo Unión Europea" />
                    </div>
                </Col>
                <Col md={{ span:5 }} sm={6}>
                    <div className="logo">
                        <img className="logoiica" src={ logo_iica } alt="Logo IICA" />
                    </div>
                </Col>
            </Row>
            <h1 className="mt-5" style={{ color:"#b0bd44" }}>AGRO-INNOVA</h1>
            <h2>SISTEMAS AGROFORESTALES ADAPTADOS PARA EL CORREDOR SECO CENTROAMERICANO</h2>            
        </div>
    )
}

export default Title