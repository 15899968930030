import { Col, Container, Row } from "react-bootstrap";
import Indicador from "../../components/Indicador";
import icon4 from "../../static/indicadores/icon4.png"
import icon27 from "../../static/indicadores/icon27.png"
import icon16 from "../../static/indicadores/icon16.png"
import icon13 from "../../static/indicadores/icon13.png"
import icon30 from "../../static/indicadores/icon30.png"
import icon23 from "../../static/indicadores/icon23.png"
import icon31 from "../../static/indicadores/icon31.png"
import icon5 from "../../static/indicadores/icon5.png"
import icon7 from "../../static/indicadores/icon7.png"
import icon2 from "../../static/indicadores/icon2.png"
import icon20 from "../../static/indicadores/icon20.png"

const Logros = () => {
    return (
      <Container className="mt-5 mb-3">
        <Row>
            <Col md={12}><h4 className="mb-5" style={{ textAlign:"center" }}>Entre 2019 al 2021 el proyecto AGRO-INNOVA ha logrado</h4></Col>
            <Indicador image={icon4} number="33" text="Territorios de Intervención" />
            <Indicador image={icon4} number="94" text="Localidades dentro de los territorios de intervención" />
            <Indicador image={icon27} number="76,89" text="# de Ha con Manejo SAFM en Parcelas demostrativas" />
            <Indicador image={icon16} number="1591,13" text="# de Ha con Manejo SAFM en Fincas de productores" />
            <Indicador image={icon16} number="104" text="# de Parcelas demostrativas con implementación de innovaciones en SAFM" />
            <Indicador image={icon13} number="21" text="# de innovaciones en SAFM replicadas en parcelas demostrativas" />
            <Indicador image={icon30} number="21" text="# de socios nacionales vinculados al proyecto " />
            <Indicador image={icon23} number="12" text="# de organizaciones de productores con iniciativas en SAFM" />
            <Indicador image={icon31} number="1727" text="# Productores(as) capacitados por medio de la estrategia de formación de capacidades" />
            <Indicador image={icon5} number="1601" text="# Productores(as) con asistencia técnica" />
            <Indicador image={icon7} number="114" text="# de acciones de formación de Capacidades en SAFM" />
            <Indicador image={icon2} number="300" text="# Gestión de Contenidos técnicos en el Hub Regional Agropecuario: AGRO-INNOVA" />
            <Indicador image={icon20} number="6" text="# de aplicativos webs desarrollados" />
        </Row>
      </Container>
    )
}

export default Logros