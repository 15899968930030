import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";

const AppRouter = ({ children }) => {

    return (
        <Router>
            { children }
            <Routes>
                <Route exact path="/" element={ <Home /> } />
            </Routes>
        </Router>
    )
}

export default AppRouter