import Title from "../components/Title";
import Parallax from "../layouts/Parallax";
import ParallaxImage from "../components/ParallaxImage";
import home from "../static/images/home.jpg"
import objetivos from "../static/images/objetivos.jpg"
import resultados from "../static/images/resultados.jpg"
import socios from "../static/images/socios.jpg"
import indicadores from "../static/images/indicadores.jpg"
import galeria from "../static/images/galeria.jpg"
import { Col, Container, Row } from "react-bootstrap";
import Logros from "./Home/Logros";
import Galeria from "./Home/Galeria";
const Home = () => {
    return (
      <Parallax>        
        <Title />
        <ParallaxImage image={home}/>
        <Descripcion />
        <ParallaxImage image={objetivos}/>
        <Objetivos />
        <ParallaxImage image={resultados}/>
        <Resultados />
        <ParallaxImage image={socios}/>
        <Socios />
        <ParallaxImage image={indicadores}/>
        <Logros />
        <ParallaxImage image={galeria}/>
        <Galeria />
      </Parallax>
    );
}
  

const Descripcion = () => {
  return (
    <Container className="mt-5 mb-3">
      <Row>
        <Col md={12}>
          <h3><strong>Descripción</strong> del proyecto</h3>
          <p>El proyecto AGRO-INNOVA es implementado por el Instituto Interamericano de Cooperación para la Agricultura (IICA), con la asistencia financiera de la Unión Europea (UE), en asocio con 21 socios nacionales públicos y privados, en seis países Centroamericanos: Guatemala, El Salvador, Honduras, Nicaragua, Costa Rica y Panamá, así como con el apoyo técnico y de investigación del Centro Agronómico Tropical de Investigación y Enseñanza (CATIE). </p>
          <p>Por medio de la Gestión del Conocimiento, la Formación de Capacidades y las Alianzas Estratégicas Público-Privadas, plantea 5 estrategias específicas de intervención para mejorar la seguridad alimentaria, el desarrollo productivo y resiliencia climática pequeños productores altamente vulnerables, que habitan en el Corredor Seco Centroamericano, las cuales son: </p>
          <ol>
            <li>Modelos en Sistemas Agroforestales Adaptados a pequeños productores</li>
            <li>Gestión Institucional: Red Técnica Nacionales y Regional de intercambio y sinergias para la gestión de conocimiento en SAF</li>
            <li>Desarrollo de Vitrinas de Innovación Tecnológica en SAFM con productores líderes e iniciativas con organizaciones de productores</li>
            <li>Fomento de Innovación disruptivas: Tecnologías y buenas prácticas, agropecuarias, forestales y de agricultura digital</li>
            <li>Formación de capacidades para la adopción y escalamiento de innovaciones en SAFM</li>
          </ol>
          <p>La promoción, difusión e implementación de tecnologías y buenas prácticas en Sistemas Agroforestales Multiestrato, fomentando experiencias de adopción y escalamiento de innovaciones en el manejo agroforestal, conservación de suelos, gestión integrada del recurso hídrico, y el manejo integrado de la producción agropecuaria, de la mano de productores, técnicos de organizaciones de producción y socios institucionales públicos y privados de investigación transferencia agropecuaria y extensión rural.</p>
          <p>La meta AGRO-INNOVA, busca fortalecer las capacidades y la gestión de conocimiento de 3000 pequeños productores y 12 organizaciones en seis países del Corredor Seco Centroamericano.</p>
        </Col>
      </Row>
    </Container>
  )
}

const Objetivos = () => {
  return (
    <Container className="mt-5 mb-3">
      <Row>
        <Col md={12}>
          <h4>Objetivo del proyecto</h4>
          <p className="mb-4">Contribuir a mejorar la resiliencia climática y la seguridad alimentaria de los hogares altamente vulnerables de los pequeños productores en América Central</p>
          <h4>Objetivo específico</h4>
          <p>Mejorar las tecnologías de adaptación y mitigación al cambio climático para la producción de cultivos básicos y ganadería de pequeños productores a través de la investigación, transferencia y extensión en SAFM, a fin de preservar la biodiversidad de las semillas, aumentar la productividad y mejorar la seguridad alimentaria de las familias altamente vulnerables en el Corredor Seco de Centroamérica.</p>
        </Col>
      </Row>
    </Container>
  )
}

const Resultados = () => {
  return (
    <Container className="mt-5 mb-3">
      <Row>
        <Col md={12}>
          <h4>Resultados del proyecto</h4>
          <p>La Unión Europea y el IICA, Trabajamos Juntos de la mano de 21 socios nacionales públicos y privados en lograr que pequeños productores del corredor seco centroamericano y organizaciones de pequeños productores: </p>
          <ul>
            <li>Cuenten con modelos de gestión para el desarrollo y establecimiento de estrategias para la innovación, mediante el fortalecimiento de capacidades en la producción agrícola y pecuaria, a través SAFM.</li>
            <li>Implementen los modelos de innovación a través de parcelas demostrativas con SAFM.</li>
            <li>Articulen estrategias para la gestión del conocimiento en SAFM que fortalezcan las capacidades de los Institutos de Investigación, los Ministerios de Agricultura, las universidades y las organizaciones de pequeños productores.</li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

const Socios = () => {
  return (
    <Container className="mt-5 mb-3">
      <Row>
        <Col md={12}>
          <h4>Contrapartes y socios del proyecto</h4>
          <ul>
            <li><strong>Asistencia técnica Financiera:</strong> Unión Europea</li>
            <li><strong>Subdelegado Técnico en investigación:</strong> Centro Agronómico Tropical de Investigación y Enseñanza</li>  
            <li>
              <strong>Socios nacionales</strong>
              <ul>
                <li>Ministerios de Agricultura y Ganadería de Guatemala, El Salvador, Honduras, Nicaragua, Costa Rica y Panamá.</li>
                <li>Asociación Nacional del Café (ANACAFE)</li>
                <li>Centro Nacional de Tecnología Agropecuaria y Forestal de El Salvador (CENTA)</li>
                <li>Dirección de Ciencia y Tecnología Agropecuaria (DICTA)</li>
                <li>Instituto Hondureño del Café (IHCAFE)</li>
                <li>Instituto Nicaragüense de Tecnología Agropecuaria (INTA - Nicaragua)</li>
                <li>Instituto del Café de Costa Rica (ICAFE)</li>
                <li>Cámara Nacional de Productores de Leche (CNPL)</li>
                <li>Cooperativa Agropecuaria Regional de productores de leche (COOPELECHE R.L)</li>
                <li>Consejo Nacional de Clubes 4S (CONAC 4S)</li>
                <li>Dirección Nacional de Extensión Agropecuaria (DNEA)</li>
                <li>Ministerio de Desarrollo Agropecuario</li>
                <li>Instituto de Innovación Agropecuaria de Panamá</li>
                <li>Facultad de Ciencias Agropecuarias</li>
                <li>Centro de Competitividad de la Región Occidental de Panamá</li>
              </ul>
            </li>
          </ul>          
        </Col>
      </Row>
    </Container>
  )
}

export default Home;