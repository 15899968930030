import "./ParallaxImage.scss"

const ParallaxImage = ({ image, height='500px' }) => {
    return (
        <div className="parallax-image" style={{ backgroundImage:`url(${image})`, height:height }}>
            
        </div>
    )
}

export default ParallaxImage